import { useState, useEffect } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import FAQs from 'components/pages/WhyEnroll/faq';
import Nav from 'components/Nav';
import Content from 'components/Content';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';
import Footer from 'components/Footer';
import Button from 'components/Button';
import Dots from 'components/Dots';

import classes from 'styles/pages/Services/Enroll.module.scss';

import Clipboard from 'icons/services/clipboard.svg';
import Human from 'icons/services/human.svg';
import Pencil from 'icons/services/pencil.svg';
import QuoteIcon from 'icons/quote2.svg';

import { EMPLOYERS_LINK, REGISTER, FEDERAL_BENEFITS } from 'utils/constants/urls';
import LAWYER_HOURLY_RATE from 'utils/constants/attorneys';

const navLinks = [
  { text: 'Why MetLife Legal Plans?', href: '/why-enroll' },
  { text: 'How We Work', href: '/#how-we-work' },
  { text: 'Reviews', href: '/#reviews' },
  { text: 'About Us', href: '/about-us' },
  { text: 'For Employers', href: EMPLOYERS_LINK, external: true },
  { text: 'For Federal Government', href: FEDERAL_BENEFITS, external: true },
];

const testimonials = [
  {
    quote:
      'Matt was selling his house and buying a new one in another state. He began facing challenges with the buyers of his old home as well as the new house builder. A network attorney was able to guide him through the entire process and make the move easier.',
    name: 'Matt',
    savings: 6420,
  },
  {
    quote:
      'After years of procrastinating, Jenna and her husband established a will, which gave them comfort for the future of their 4-year-old. Jenna also had the attorney review documents from her father, which saved their family a lot of trouble when he passed away unexpectedly.',
    name: 'Jenna',
    savings: 1240,
  },
  {
    quote:
      'I received a traffic ticket in the mail (was caught on camera). I have never received a ticket in my life and I am 60 years old. I panicked and called my plan attorney’s office and was given his cell phone number. That right there stunned me. I proceeded to call him, left a message and 10 minutes later, he called back. He took care of the entire deal for me. He is an extremely caring and professional individual. Thank you, you saved my life.',
    name: 'Wendy',
    savings: 870,
  },
];

const WhyEnroll = () => {
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const next = (slide + 1) % testimonials.length;
    const timeout = setTimeout(() => setSlide(next), 5000);

    return () => clearTimeout(timeout);
  }, [slide]);

  return (
    <>
      <Nav links={navLinks} />

      <main role="main">
        <div className={classes.introSection}>
          <div className={classes.left}>
            <div className={classes.text}>
              <Typography variant="h1">Legal help made easy</Typography>

              <Spacer v={32} />

              <Typography variant="h4">
                MetLife Legal Plans makes it easy to get the legal help you need throughout your
                life with no copays, deductibles or claim forms when using a network attorney for a
                covered matter.
              </Typography>

              <Spacer v={48} />
            </div>
          </div>

          <div className={classes.right}>
            <Typography className={classes.listHeader}>
              Create an account and get access to:
            </Typography>
            <Spacer v={30} />

            <div className={classes.text}>
              <Typography variant="body1">
                Full coverage information to see how the plan can help you
              </Typography>

              <Spacer v={22} />

              <Typography variant="body1">
                A tour of our digital estate planning solution to begin creating documents you can
                finalize once you enroll
              </Typography>

              <Spacer v={22} />

              <Typography variant="body1">View our attorney network and Google reviews</Typography>

              <Spacer v={22} />

              <Typography variant="body1">
                Self-help documents to handle simple legal matters
              </Typography>

              <Spacer v={35} />

              <Button
                role="link"
                filled
                color="green"
                onClick={() => {
                  window.location = REGISTER;
                }}
              >
                Create Account
              </Button>

              <Spacer v={18} />

              <Typography variant="body3" align="center" className={classes.disclaimer}>
                By creating this account, you are not enrolling in the plan
              </Typography>
            </div>
          </div>
        </div>

        <Content className={classes.howItWorks}>
          <Typography variant="h1">How It Works Once You're Enrolled</Typography>

          <Spacer v={40} />

          <div className={classes.iconSection}>
            <div className={classes.iconWithText}>
              <Human />
              <Spacer v={24} />

              <Typography variant="h3">
                Easy to find
                <br />
                an attorney
              </Typography>

              <Spacer v={24} />

              <Typography>
                Create an{' '}
                <a href={REGISTER} className={classes.link}>
                  account
                </a>{' '}
                to find an attorney, get an Eligibility ID or complete digital estate planning.
              </Typography>
            </div>

            <div className={classes.iconWithText}>
              <Pencil />
              <Spacer v={24} />

              <Typography variant="h3">
                Easy to make <br />
                an appointment
              </Typography>

              <Spacer v={24} />

              <Typography>
                Call the attorney you select, provide your Eligibility ID and schedule a time to
                talk or meet.
              </Typography>
            </div>

            <div className={classes.iconWithText}>
              <Clipboard />
              <Spacer v={24} />

              <Typography variant="h3">
                Easy from <br />
                start to finish
              </Typography>

              <Spacer v={24} />

              <Typography>
                That's it! There are no copays, deductibles or claims forms when you use a network
                attorney for a covered matter.
              </Typography>
            </div>
          </div>
        </Content>

        <div className={classes.memberStories}>
          <Typography variant="quote" className={classes.headerText}>
            REAL MEMBER STORIES
          </Typography>

          <Spacer v={56} />

          <div className={classes.carousel}>
            {testimonials.map((testimonial, i) => {
              const hiddenSlideClass = slide > i && slide !== i ? classes.previous : classes.next;

              return (
                <div
                  key={testimonial.name}
                  className={classNames(
                    classes.slide,
                    slide === i ? classes.current : hiddenSlideClass,
                  )}
                >
                  <img
                    src={`/members/${testimonial.name.toLowerCase()}.png`}
                    alt={`MetLife legalplans member ${testimonial.name}`}
                    className={classes.image}
                  />

                  <div className={classes.text}>
                    <QuoteIcon />

                    <Typography variant="body2">{testimonial.quote}</Typography>
                    <Typography variant="subheader2" className={classes.savingsText}>
                      {testimonial.name}'s potential savings: $
                      {testimonial.savings.toLocaleString()}
                    </Typography>

                    <Spacer v={16} />

                    <Typography variant="body2">
                      {testimonial.name} | MetLife Legal Plans Member
                    </Typography>

                    <QuoteIcon className={classes.iconReverse} />
                  </div>
                </div>
              );
            })}
          </div>

          <Dots
            light
            selected={slide}
            onClick={i => {
              setSlide(i);
            }}
          />

          <Spacer v={24} />

          <Button
            role="link"
            filled
            color="green"
            onClick={() => {
              window.location = REGISTER;
            }}
          >
            Create Account
          </Button>
        </div>

        <div className={classes.sectionEstatePlanning}>
          <Content>
            <Typography variant="h1">Estate planning at your fingertips</Typography>

            <Spacer v={32} />

            <Typography variant="quote" upcase className={classes.subHeading}>
              Included in the legal plan
            </Typography>

            <div className={classes.box}>
              <Typography className={classes.largeFont}>70%</Typography>
              <Typography variant="quote">
                of Americans do not have a will, the most basic estate planning document you need to
                protect your assets and your family.<sup>1</sup>
              </Typography>
            </div>
          </Content>
        </div>

        <div className={classes.info}>
          <Typography variant="h4">
            Most of us know we need important estate planning documents like wills, advance
            directives and powers of attorney, but finding the time to complete these documents can
            be a challenge. Through our digital estate planning solution we make it easy for you to
            get this done.
          </Typography>

          <Spacer v={16} />

          <Typography variant="h4">
            With our digital estate planning solution, you can create:
          </Typography>
        </div>

        <div className={classes.wills}>
          <div className={classes.gradientBox}>
            <Typography variant="h2">Last Will and Testament</Typography>

            <Spacer v={40} />

            <Typography variant="h4">
              Leave property to loved ones and choose guardians for minor children.
            </Typography>
          </div>

          <div className={classes.gradientBox}>
            <Typography variant="h2">Advance Directive</Typography>

            <Spacer v={40} />

            <Typography variant="h4">
              Plan for a medical emergency and select medical care preferences.
            </Typography>
          </div>

          <div className={classes.gradientBox}>
            <Typography variant="h2">Durable Financial Power of Attorney</Typography>

            <Spacer v={40} />

            <Typography variant="h4">
              Leave property to loved ones and choose guardians for minor children.
            </Typography>
          </div>
        </div>

        <Content className={classes.videoSection}>
          <Typography variant="h2">
            See how easy it is to use our digital estate planning solution. Create{' '}
            <a href={REGISTER} className={classes.link}>
              an account
            </a>{' '}
            to get started on your documents that you can finish once you enroll.
          </Typography>

          <Spacer v={64} />

          <div className={classes.videoWrapper}>
            <iframe
              title="vimeo-player"
              width="1021"
              height="574"
              className={classes.video}
              src="https://player.vimeo.com/video/409000730?portrait=0&byline=0&title=0"
              frameBorder="0"
              allowFullScreen
            />
          </div>

          <Spacer v={48} />

          <Button
            role="link"
            filled
            color="green"
            onClick={() => {
              window.location = REGISTER;
            }}
          >
            Create Account
          </Button>
        </Content>

        <Content className={classes.cost}>
          <Typography variant="h2">
            The cost of MetLife Legal Plans coverage for the whole year is less than the average
            attorney’s hourly fee.<sup>2</sup>
          </Typography>

          <Spacer v={48} />

          <div className={classes.costComparison}>
            <img src="/calculator.png" alt="" />
            <Typography variant="h1">
              ${LAWYER_HOURLY_RATE} <Spacer v={16} />
              <span>an hour</span>
            </Typography>

            <Typography variant="quote" className={classes.vs}>
              vs.
            </Typography>

            <Typography variant="h1">
              $240 <Spacer v={16} />
              <span>a year</span>
              <sup>3</sup>
            </Typography>
          </div>
        </Content>

        <div className={classes.calculate}>
          <Typography variant="h1" className={classes.title}>
            {' '}
            See how much you can save!
          </Typography>

          <Link href="/calculator">
            <a>
              <Typography variant="calltoaction1" className={classes.linkButton}>
                Plan Calculator
              </Typography>
            </a>
          </Link>
        </div>

        <div className={classes.costTableSection}>
          <div className={classes.tableSection}>
            <Typography variant="h1" className={classes.tableSectionHeader}>
              See the cost savings for some of our most frequently used coverages:
            </Typography>

            <Spacer v={48} />

            <table>
              <thead>
                <tr>
                  <td>
                    <Typography variant="quote">Covered Services</Typography>
                  </td>
                  <td>
                    <Typography variant="quote">Without a Legal Plan</Typography>
                  </td>
                  <td>
                    <Typography variant="quote">With a Legal Plan</Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.lightRow}>
                  <td>Wills for employee &amp; spouse</td>
                  <td>$740</td>
                  <td>$0 out of pocket</td>
                </tr>
                <tr>
                  <td>Medical power of attorney </td>
                  <td>$185</td>
                  <td>$0 out of pocket</td>
                </tr>
                <tr className={classes.lightRow}>
                  <td>Traffic ticket defense</td>
                  <td>$740</td>
                  <td>$0 out of pocket</td>
                </tr>
                <tr>
                  <td>Home refinancing</td>
                  <td>$1,850</td>
                  <td>$0 out of pocket</td>
                </tr>
                <tr className={classes.lightRow}>
                  <td>Total</td>
                  <td>$3,515</td>
                  <td>
                    $240 per year<sup>3</sup>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Typography variant="quote">Potential savings</Typography>
                  </td>
                  <td />
                  <td>
                    <Typography variant="quote">$3,275</Typography>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className={classes.image} />
        </div>

        <FAQs />

        <Content className={classes.footnotes}>
          <ol>
            <li>
              CARAVAN survey conducted by ENGINE on behalf of MetLife Legal Plans, January 2020.
            </li>
            <li>
              Average hourly rate of ${LAWYER_HOURLY_RATE}.00 based on years of legal experience,
              National Law Journal and ALM Legal Intelligence, Survey of Law Firm Economics (2021).
            </li>
            <li>Average monthly rate for MetLife Legal Plans.</li>
            <li>
              You will be responsible to pay the difference, if any, between the plan’s payment and
              the out-of-network attorney’s charge for services.
            </li>
          </ol>
        </Content>

        <div className={classes.getStarted}>
          <Typography variant="h1">Let's Get Started</Typography>

          <Button
            filled
            color="blue"
            onClick={() => {
              window.location = REGISTER;
            }}
          >
            Create Account
          </Button>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default WhyEnroll;
