import { useState } from 'react';
import { Markup } from 'interweave';

import Typography from 'components/Typography';
import Content from 'components/Content';

import classes from 'styles/pages/Home/FAQs.module.scss';

import CaratIcon from 'icons/accordion-carat.svg';

const faqs = [
  {
    question: 'How does the plan work?',
    answer: [
      'Getting started is easy. You simply choose an attorney from our network, which is available online or by calling our Client Service Center. We will then give you an assigned Eligibility ID to share with your attorney when you make an appointment. It’s that simple.',
      'You can speak to our network attorneys face to face or by phone, or you can submit questions online to our Law Firm E-Panel<sup>®</sup> — whatever works best for you. And for certain legal matters, your attorney can represent you in court without you having to make an appearance.',
    ],
  },
  {
    question: 'Can I get help finding the right attorney for my needs?',
    answer: [
      'Yes, our Client Service Center representatives are here to help you find the right attorney for your legal matter, whatever that might be. We’re committed to ensuring you receive the expert legal help you need, when you need it.',
    ],
  },
  {
    question: 'How are attorneys selected for the network? What are their qualifications?',
    answer: [
      'We only select attorneys who meet our selection criteria and agree to our Attorney Code of Excellence.',
      'Attorneys in the network have an average of 25 years’ experience in the practice of law, have graduated from an accredited law school, and must maintain valid state licensure. Additionally, the attorneys must agree to provide superior customer service to all legal plan members.',
      'We routinely monitor our attorneys to ensure our members’ needs are being met and conduct an regular re-credentialing audit that looks at legal activity, member feedback, verification of malpractice insurance, and more.',
    ],
  },
  {
    question: 'Can I use an attorney who is not in the network?',
    answer: [
      'Yes, you can use any attorney you’d like. If you choose an attorney outside of our network, we’ll reimburse you for services based on a set fee schedule.<sup>4</sup>',
    ],
  },
  {
    question: 'Can I use an attorney more than once?',
    answer: [
      'Yes, you have unlimited use of the plan over the course of the year for covered legal matters.',
    ],
  },
  {
    question: 'How much will it cost?',
    answer: [
      'Less than you might think. For less than a dollar a day, you can have our legal experts on your side, for as long as you need them. You can find the exact cost for your plan in the enrollment materials provided by your employer.',
    ],
  },
  {
    question: 'How do I pay for my coverage?',
    answer: [
      'It’s easy. Your premium is paid through payroll deductions, so you don’t have to worry about writing any checks or missing payments.',
    ],
  },
  {
    question: 'Are my spouse and family members also covered by my plan?',
    answer: [
      'Most plans cover your spouse and dependent children. Please see the details of your plan.',
    ],
  },
  {
    question: 'Are claim forms required when using the legal plan?',
    answer: [
      'No. We make using your plan easy. When you use a network attorney, there is nothing for you to do. Plan services are covered in full, and billing is between us and the network attorney. There are no waiting periods, no copays, no deductibles and no claims forms.',
    ],
  },
];

const FAQs = () => {
  const [showAtIndex, setShowAtIndex] = useState(-1);

  const handleChange = i => {
    setShowAtIndex(i);
    if (showAtIndex === i) {
      setShowAtIndex(-1);
    }
  };

  return (
    <Content>
      <div className={classes.accordion}>
        <Typography variant="h1" className={classes.title}>
          Frequently Asked Questions
        </Typography>

        {faqs.map(({ question, answer }, index) => (
          <div key={question} className={classes.accordionItem}>
            <button
              className={classes.accordionButton}
              type="button"
              aria-expanded="true"
              aria-controls="collapse"
              onClick={() => handleChange(index)}
            >
              <CaratIcon
                className={
                  showAtIndex === index ? classes.accordionCarat : classes.accordionCaratHide
                }
              />
              <Typography variant="h2" className={classes.accordionHeader}>
                {question}
              </Typography>
            </button>

            {showAtIndex === index && (
              <div className={classes.accordionBody}>
                {answer.map(ans => (
                  <Typography key={ans} variant="body1">
                    <Markup content={ans} />
                  </Typography>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </Content>
  );
};

export default FAQs;
